.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  /* padding: 1rem; */
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.2rem rgb(0 0 0 / 5%);
  margin: 1vh;
  width: 85%;

}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  background-color: #f4f6f9;
  flex-direction: column;
  width: 100%;
}

.container h1,
.container p {
  text-align: center;
}
/* 
button {
  width: 80%;
  margin: 0 10%;
  margin-top: 20px;
} */

.inputField {
  width: 100%;
  margin: 10px;
}
.inputField > div {
  width: 80%;
  margin: 0 10%;
}
label {
  width: 80%;
  margin: 0 10%;
  margin-top: 10px;
}

.radio {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 1rem;
}

.radio label {
  margin: 0 0;
  width: fit-content;
}
.dialogHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #efefef;
  border-radius: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.dialogHeader h2 {
  text-align: center;
  color: rgb(255, 255, 255);
  padding: 1rem;
  font-size: 1.5rem;
}

.dialogHeader span {
  padding: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
}

.uploadBox {
  height: 5rem;
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;

}

.loader{
  height: 6rem;
  width: 6rem;
  margin-left: 2rem;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  border-top: 5px solid rgb(78, 176, 203);

}

@keyframes spin {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}
.dropzone {
  height: 5rem; 
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #ccc; 
  padding: 1rem;
}
.imagePreview {
  margin-top: 1rem; 
}
