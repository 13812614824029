.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.2rem rgb(0 0 0 / 5%);
  margin: 1vh;
  width: 75%;
}

.filesUploadContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: start;
  flex-wrap:wrap;
  
  /* margin: 1vh; */
  /* width: 75%;  */
  width: 100%;
  margin: 10px;
  
}



.main {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f4f6f9;
  flex-direction: column;
  width: 100%;
}

.container h1,
.container p {
  text-align: center;
}

.buttonForm{
  background-color: #f28f3b;
  color:white;
  border: 1px solid;
  padding: 5px;
  width: 7rem;
  height: 3rem;
  border-radius: 15px;
}



.inputField {
  width: 100%;
  margin: 10px;
}

.instituteSearch{
  border:1px solid #DbDbDb;
  width: 100%;
  border-radius: 5px;

  padding: 8px;
}
.inputField > div {
  width: 80%;
  margin: 0 10%;
}
label {
  width: 80%;
  margin: 0 10%;
  margin-top: 10px;
}

.radio {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.radio label {
  margin: 0 0;
  width: fit-content;
}

@media screen and (max-width: 800px) {
  .container {
    width: 75%;
  }
}

@media screen and (max-width: 600px) {
  .container {
    width: 90%;
  }
}



