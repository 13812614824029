.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  /* padding: 1rem; */
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.2rem rgb(0 0 0 / 5%);
  margin: 1vh;
  width: 50%;
}
.inputField {
  width: 100%;
  margin: 10px;
}

.instituteSearch{
  border:1px solid #DbDbDb;
  width: 100%;
  border-radius: 5px;

  padding: 8px;
}
.inputField > div {
  width: 80%;
  margin: 0 10%;
}
.main {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  background-color: #f4f6f9;
  flex-direction: column;
  width: 100%;
}

.container h1,
.container p {
  text-align: center;
}
/* 
button {
  width: 80%;
  margin: 0 10%;
  margin-top: 20px;
} */

.inputField {
  width: 100%;
  margin: 10px;
}
.inputField > div {
  width: 80%;
  margin: 0 10%;
}
label {
  width: 80%;
  margin: 0 10%;
  margin-top: 10px;
}

.radio {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 1rem;
}

.radio label {
  margin: 0 0;
  width: fit-content;
}
.dialogHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #efefef;
  border-radius: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.dialogHeader h2 {
  text-align: center;
  color: red;
  padding: 1rem;
  font-size: 1.5rem;
}

.dialogHeader span {
  padding: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .container {
    width: 75%;
  }
}

@media screen and (max-width: 600px) {
  .container {
    width: 90%;
  }
}
