.backdrop {
  position: fixed;
  background-color: #00000068;
  border-radius: 8px;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  top: 0;
  left: 0;
}

.errorContainer {
  position: absolute;
  background-color: #fff;
  border-radius: 8px;
  padding: 30px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 20px;
}

.cross {
  height: 30px;
  width: 30px;
  margin: 30px 20px;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;
}

.close img {
  height: 20px;
  width: 20px;
}

.errorContainer h2 {
  color: rgb(253, 29, 96);
}
