.labelText {
  font-weight: 500;
  font-size: 1rem;
  color: #000000;
}

@media only screen and (max-width: 700px) {
  .labelText {
    font-weight: 400;
    font-size: 0.875rem;
  }
}
