.container {
  background-color: white;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0.5rem 5rem;
}

.button {
  background-color: #f5f5f5;
  border: 1px solid #ababab;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: #000;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0.5rem 2rem;
  }
}
