.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  max-width: 600px;
  padding: 1rem;
  border-radius: 1rem;
  min-height: 600px;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 5%);
  margin: 10vh;
}
.main {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f4f6f9;
}

.container h1,
.container p {
  text-align: center;
}

/* button {
  width: 80%;
  margin: 0 10%;
  margin-top: 50px;
} */

.inputField {
  width: 100%;
  margin: 10px;
}
.inputField > div {
  width: 80%;
  margin: 0 10%;
}
.image {
  height: 5rem;
}

.container button {
  margin-top: 50px;
}

.footerText {
  margin-top: 5rem;
  display: flex;
  align-items: center;
}

.footerImage {
  height: 2rem;
}

@media only screen and (max-width: 700px) {
  .container {
    margin: 2rem;
    min-height: 70vh;
  }
}

.heroImage {
  width: 100%;
  margin: 3rem 0;
}

.holdOnTextContainer {
  background: #fff7f1;
  border-radius: 1rem;
  margin: 1rem 0;
  padding: 0 1rem;
}
.holdOnHeaderText {
  color: #f28f3b;
  font-size: 1.3rem;
  font-weight: bold;
}
.holdOnHelperText {
  font-size: 0.9rem;
  margin: 0;
  color: #b4bfd5;
  margin-top: 1rem;
}
.holdOnHelperTextSmall {
  font-size: 0.9rem;
  margin: 0;
  color: #f28f3b;
}
